import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react'
import { LabelInline } from '@components/label/LabelInline'
import { RadioGroup, RadioGroupItem } from '@components/radioGroup/RadioGroup'
import { AgilityApp } from '@services/agilityApps/constants'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

interface AgilityAppFormProps {
  initialData: Partial<AgilityApp>
  onFormDataChange?: (data: Partial<AgilityApp>) => void
  onSubmit: (data: AgilityApp) => void
  isUpdate: boolean
}

export const AgilityAppForm: React.FC<AgilityAppFormProps> = ({
  initialData,
  onSubmit,
  isUpdate,
  onFormDataChange,
}) => {
  const [formData, setFormData] = useState<AgilityApp>({
    ...initialData,
    suggested_questions: initialData.suggested_questions || [''],
    is_sales_demo: initialData.is_sales_demo || false,
  } as AgilityApp)

  useEffect(() => {
    if (onFormDataChange) {
      onFormDataChange(formData)
    }
  }, [formData, onFormDataChange])

  const isValidUrl = (url_str: string) => {
    try {
      new URL(url_str)
      return true
    } catch (error) {
      return false
    }
  }

  const isValidJson = useCallback((json_str: string) => {
    try {
      JSON.parse(json_str)
      return true
    } catch (error) {
      return false
    }
  }, [])

  const crawlerConfigError = useMemo(() => {
    if (!isValidJson(formData.crawler_config)) {
      return 'Invalid crawler config JSON'
    }
    const crawlerConfigJson = JSON.parse(formData.crawler_config)
    if (!crawlerConfigJson.urls || !Array.isArray(crawlerConfigJson.urls)) {
      return 'Crawler config must have "urls" field with array of URLs'
    }
    if (crawlerConfigJson.urls.length === 0) {
      return 'Crawler config must contain at least one URL'
    }
    if (!crawlerConfigJson.urls.every(isValidUrl)) {
      return 'Crawler config contains invalid URLs'
    }
    return null
  }, [formData.crawler_config, isValidJson])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleQuestionChange = (index: number, value: string) => {
    const newQuestions = [...formData.suggested_questions]
    newQuestions[index] = value
    setFormData((prev) => ({ ...prev, suggested_questions: newQuestions }))
  }

  const handleAddQuestion = () => {
    setFormData((prev) => ({
      ...prev,
      suggested_questions: [...prev.suggested_questions, ''],
    }))
  }

  const handleRemoveQuestion = (index: number) => {
    const newQuestions = formData.suggested_questions.filter((_, i) => i !== index)
    setFormData((prev) => ({
      ...prev,
      suggested_questions: newQuestions.length ? newQuestions : [''],
    }))
  }

  const handleSubmit = () => {
    onSubmit(formData)
  }

  const isSubmitDisabled =
    !formData.name ||
    !formData.url_suffix ||
    !formData.crawler_config ||
    !formData.chat_title ||
    crawlerConfigError !== null

  return (
    <Stack spacing="1.5rem">
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        <GridItem>
          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input name="name" value={formData.name} onChange={handleInputChange} />
            <FormHelperText>Used to identify the app internally only.</FormHelperText>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Input name="description" value={formData.description} onChange={handleInputChange} />
            <FormHelperText>Used to identify the app internally only.</FormHelperText>
          </FormControl>
        </GridItem>
        <GridItem colSpan={2} width="50%">
          <FormControl isRequired>
            <FormLabel>URL Suffix</FormLabel>
            <Input name="url_suffix" value={formData.url_suffix} onChange={handleInputChange} />
            <FormHelperText>
              Used to create the app URL (i.e. https://rag.app/your-url-suffix). Must be unique.
            </FormHelperText>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired>
            <FormLabel>Chat Title</FormLabel>
            <Input name="chat_title" value={formData.chat_title} onChange={handleInputChange} />
            <FormHelperText>Title the user will see on the chat app.</FormHelperText>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel>Chat Subtitle</FormLabel>
            <Input
              name="chat_subtitle"
              value={formData.chat_subtitle}
              onChange={handleInputChange}
            />
            <FormHelperText>Subtitle the user will see on the chat app.</FormHelperText>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired isInvalid={crawlerConfigError !== null}>
            <FormLabel>Crawler Config</FormLabel>
            <Textarea
              name="crawler_config"
              value={formData.crawler_config}
              onChange={handleInputChange}
              placeholder="Enter JSON configuration"
              isDisabled={isUpdate}
            />
            <FormHelperText>Configuration used to crawl URLs for the app.</FormHelperText>
            {crawlerConfigError && <FormErrorMessage>{crawlerConfigError}</FormErrorMessage>}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired>
            <FormLabel>App Type</FormLabel>
            <RadioGroup
              value={formData.is_sales_demo ? 'external_demo' : 'internal_test'}
              onValueChange={(value: string) =>
                setFormData((prev) => ({ ...prev, is_sales_demo: value === 'external_demo' }))
              }
            >
              <LabelInline content="External Demo App (for sales prospect)">
                <RadioGroupItem value="external_demo" />
              </LabelInline>
              <LabelInline content="Internal Test App (for internal testing/dogfooding)">
                <RadioGroupItem value="internal_test" />
              </LabelInline>
            </RadioGroup>
            <FormHelperText>Used for tracking & metrics.</FormHelperText>
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl>
            <FormLabel>Suggested Questions</FormLabel>
            {formData.suggested_questions.map((question, index) => (
              <Grid key={index} templateColumns="1fr auto" gap={2} mb={2}>
                <Input
                  value={question}
                  onChange={(e) => handleQuestionChange(index, e.target.value)}
                  placeholder={`Question ${index + 1}`}
                />
                <Button onClick={() => handleRemoveQuestion(index)} colorScheme="red" size="sm">
                  <DeleteIcon />
                </Button>
              </Grid>
            ))}
            <FormHelperText>
              Suggested questions the user will see on the chat app. Clicking a question will send a
              message to the chat app.
            </FormHelperText>
            <Button onClick={handleAddQuestion} leftIcon={<AddIcon />} size="sm" mt={2}>
              Add Question
            </Button>
          </FormControl>
        </GridItem>
      </Grid>
      <Button colorScheme="blue" onClick={handleSubmit} isDisabled={isSubmitDisabled}>
        Submit
      </Button>
    </Stack>
  )
}
